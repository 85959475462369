html, body {
  font-family: 'Open Sans', serif;
}

.hero {
  &.is-info {
    background: linear-gradient(
                    rgba(0, 0, 0, 0.5),
                    rgba(0, 0, 0, 0.5)
    ), url('https://unsplash.it/1200/900?blur') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  &.is-error {
    background: linear-gradient(
                    rgba(0, 0, 0, 0.5),
                    rgba(0, 0, 0, 0.5)
    ), url('https://unsplash.it/1200/900?blur&grayscale') no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  .nav, .hero.is-success .nav {
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .subtitle {
    padding: 3rem 0;
    line-height: 1.5;
  }

  .navbar-item {
    img {
      max-height: 100%
    }
  }
}
